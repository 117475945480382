import React from "react";
import style from "./profilePhoto.styles.module.scss";
import PropTypes from "prop-types";

ProfilePhoto.propTypes = {
  photoUrl: PropTypes.string,
  altText: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
ProfilePhoto.defaultProps = {
  altText: "",
  width: 7,
  height: 7,
  className: "",
};

function ProfilePhoto(props) {
  return (
    <div
      style={{
        backgroundImage: `url(${props.photoUrl})`,
      }}
      className={`${props.className} ${style.profileDiv}`}
    />
  );
}

export default ProfilePhoto;
