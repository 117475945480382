import React from "react";
import { graphql } from "gatsby";
import Page from "../components/layout/page.component";
import Layout from "../components/layout/layout.component";
import Titantron from "../components/titantron/titantron.component";
import mainVideo from "../images/videos/mainvideo.mp4";
import PictureArticle from "../components/content/pictureArticle.component.jsx";
import { Helmet } from "react-helmet";
import Conversion from "../components/content/conversion.component";
import PropTypes from "prop-types";
import StandardHeadline from "../components/content/elements/text/standardHeadline.component";
import StandardText from "../components/content/elements/text/standardText.component";
import ProfilePhoto from "../components/content/profilePhoto.component";
import kriegenPhoto from "../siteContent/secondaryPage/images/kriegen.jpg";
import muffPhoto from "../siteContent/secondaryPage/images/florianmoll.png";
import payne from "../siteContent/secondaryPage/images/payne.jpg";
import reger from "../siteContent/secondaryPage/images/reger.jpeg";

export default function Home({ data }) {
  let pictureArticles_bglight = data.allMarkdownRemark.edges.map(
    ({ node }, index) => {
      if (node.frontmatter.type === "white") {
        console.log(node.frontmatter);
        return (
          <PictureArticle
            key={node.id}
            headline={node.frontmatter.title}
            text={node.html}
            imageFluid={node.frontmatter.featuredImage?.childImageSharp.fluid}
            altText={node.frontmatter.altText}
            svgUrl={node.frontmatter.svgUrl}
            alignPicture={index % 2 === 0 ? "right" : "left"}
            description={`${
              node.frontmatter.date ? node.frontmatter.date + ": " : ""
            } ${node.frontmatter.description}`}
          />
        );
      }
    }
  );
  let imageSVGArticles_bgdark = data.allMarkdownRemark.edges.map(
    ({ node }, index) => {
      if (node.frontmatter.type === "dark") {
        return (
          <PictureArticle
            pseudoDivDirection="vertical"
            key={node.id}
            headline={node.frontmatter.title}
            text={node.html}
            imageFluid={node.frontmatter.featuredImage.childImageSharp.fluid}
            altText={node.frontmatter.altText}
            alignPicture={index % 2 === 0 ? "left" : "right"}
          />
        );
      }
    }
  );

  return (
    <Page>
      <Helmet>
        <meta
          httpEquiv="Access-Control-Allow-Origin"
          content="https://www.mollton-veranstaltungstechnik.de"
        />
        <meta
          name="description"
          content="Verleih von Audio-, Video-, Licht- und Bühnentechnik in Nordbayern, Sachsen und darüber hinaus."
        />
      </Helmet>
      <Layout background="dark" width="full">
        <Titantron
          title="Hinter jeder guten Veranstaltung steckt verlässliche Technik"
          underline=""
          video={mainVideo}
        />
      </Layout>
      <Layout background="trans" width="twothird">
        <div className="mw-40 align-self-center vh-100 d-flex flex-column justify-content-center">
          <StandardHeadline type="h2" className="text-center mb-5">
            Das sind Wir
          </StandardHeadline>
          <div className="d-flex align-items-center flex-wrap mw-40 w-100 mb-5 justify-content-between">
            <ProfilePhoto className="shadow-1 " photoUrl={kriegenPhoto} />
            <ProfilePhoto className="shadow-1 " photoUrl={muffPhoto} />
            <ProfilePhoto className="shadow-1 " photoUrl={reger} />
            <ProfilePhoto className="shadow-1 " photoUrl={payne} />
          </div>
          <StandardText className="text-center">
            Wir bei Mollton vermieten und betreuen seit über 7 Jahren
            Tontechnik, Lichttechnik, Bühnentechnik und seit neustem auch LED
            Technik. Zu unseren Kunden gehören verschiedenste Bands, DJs,
            Unternehmen und Berufs- und Hobbyveranstalter in Nordbayern, Sachsen
            und darüber hinaus.
          </StandardText>
        </div>
        {pictureArticles_bglight}
      </Layout>
      <Layout className="colorfulBackground" background="dark" width="full">
        <Layout background="trans" width="twothird">
          <div className="mw-40 mb-6 align-self-center d-flex flex-column justify-content-center">
            <StandardHeadline type="h2" className="text-center">
              Unsere Einsatz&shy;möglichkeiten
            </StandardHeadline>
          </div>
          {imageSVGArticles_bgdark}
        </Layout>
      </Layout>

      <Layout background="trans" width="twothird">
        <Conversion />
      </Layout>
    </Page>
  );
}

export const query = graphql`
  {
    allMarkdownRemark(sort: { fields: frontmatter___order }) {
      edges {
        node {
          id
          frontmatter {
            order
            title
            date
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            svgUrl
            svg {
              absolutePath
            }
            type
          }
          html
        }
      }
    }
  }
`;

Home.propTypes = {
  data: PropTypes.object,
};
