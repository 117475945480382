import React from "react";
import StandardHeadline from "./elements/text/standardHeadline.component";
import StandardButton from "./elements/text/standardButton.component";
import { Link } from "gatsby";

Conversion.propTypes = {};

function Conversion() {
  return (
    <div
      className={`d-flex p-3 flex-column align-items-center w-100 vh-100 justify-content-center`}
    >
      <StandardHeadline type="h3" className="mb-5 text-center">
        Na? Benötigen Sie uns für ein Event?
      </StandardHeadline>
      <StandardHeadline type="h5" className="mb-5 text-center">
        Wir nehmen gerne Kontakt mit Ihnen auf!
      </StandardHeadline>
      <Link to={`/kontakt/`}>
        <StandardButton className="standardButton--primary standardButton--primary--huge">
          Kontakt aufnehmen!
        </StandardButton>
      </Link>
    </div>
  );
}

export default Conversion;
